<template>
    <el-container class="page-container">
        <el-main class="page-main" style="padding: 0 20px">
            <br>
            <el-form label-position='left' :model="query" label-width="85px" ref="searchForm">
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item prop="date" label="订单日期">
                            <el-date-picker
                                    class="inner-input"
                                    v-model="query.date"
                                    type="daterange"
                                    value-format="timestamp"
                                    clearable
                                    range-separator="至"
                                    :default-time="['00:00:00', '23:59:59']"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                            </el-date-picker>

                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item prop="tradeno" label="订单号">
                            <el-input v-model="query.tradeno" clearable placeholder="请输入订单号" class="inner-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item prop="targetAddress" label="收获地址">
                            <el-input v-model="query.targetAddress" clearable placeholder="请输入订单号" class="inner-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item prop="goodsName" label="商品名称">
                            <el-input v-model="query.goodsName" clearable placeholder="请输入商品名称" class="inner-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item prop="targetUser" label="收货人姓名">
                            <el-input v-model="query.targetUser" clearable placeholder="请输入收货人姓名" class="inner-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item prop="targetMobile" label="收货人电话">
                            <el-input v-model="query.targetMobile" clearable placeholder="请输入收货人电话" class="inner-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item prop="mobile" label="下单人电话">
                            <el-input v-model="query.mobile" clearable placeholder="请输入下单人电话" class="inner-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item prop="orderStatus" label="订单状态">
                            <el-select v-model="query.orderStatus" placeholder="请选择订单状态" class="inner-input">
                                <el-option
                                        v-for="item in orderStatusOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item prop="payStatus" label="支付状态">
                            <el-select v-model="query.payStatus" placeholder="请选择支付状态" class="inner-input">
                                <el-option
                                        v-for="item in payStatusOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item prop="userType" label="用户类型">
                            <el-select v-model="query.userType" placeholder="请选择用户类型" class="inner-input">
                                <el-option
                                        v-for="item in userTypes"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label-width="0">
                            <el-button type="primary" @click="handleSearch">
                                <i class="cw-icon cw-icon-sousuo"></i>搜索
                            </el-button>
                            <el-button type="primary" plain @click="clearSearch">
                                <i class="cw-icon cw-icon-zhongzhi"></i>重置
                            </el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="{span:12}" :sm="12" :md="8" :lg="6"
                            style="display: flex;justify-content: flex-end">
                        <el-button type="success" :disabled="!selectArray.length" @click="handleFinishOrders">
                            完成订单
                        </el-button>
                        <el-button type="primary" :disabled="!tableData.length" @click="handleDownload">
                            <i class="cw-icon cw-icon-tianjia"></i>订单导出
                        </el-button>
                    </el-col>
                </el-row>
            </el-form>


            <el-table
                    border
                    :data="tableData"
                    key=""
                    @selection-change="handleSelectionChange"
                    style="width: 100%">
                <el-table-column
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column type="expand">
                    <template slot-scope="scope">
                        <el-form label-position="left" label-width="100px">
                            <el-form-item label="菜品">
                                <el-tag v-for="(foods,index) in scope.row.oaList" :key="index"
                                        style="margin-right: 24px">{{foods.goodsName}} x {{foods.num}}
                                </el-tag>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="tradeno"
                        label="订单号"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="targetUser"
                        label="收货人姓名"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="targetMobile"
                        label="收货人电话"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>

                <el-table-column
                        prop="targetAddress"
                        label="收货地址"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="goodsName"
                        label="商品名称"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="goodsNum"
                        label="商品数量"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="mobile"
                        label="下单人电话"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="goodPrice"
                        label="商品总价"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="payFee"
                        label="支付金额"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="orderStatus"
                        label="订单状态"
                        :show-overflow-tooltip="true"
                        :formatter="formatOrderStatus"
                >
                </el-table-column>
                <el-table-column
                        prop="payStatus"
                        label="支付状态"
                        :show-overflow-tooltip="true"
                        :formatter="formatPayStatus"
                >
                </el-table-column>
                <el-table-column
                        prop="createTime"
                        label="下单时间"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="payTime"
                        label="支付时间"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="note"
                        label="备注"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="level"
                        label="网吧用户"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.barUser==1">是</span>
                        <span v-if="scope.row.barUser==-1">否</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="staff"
                        label="员工餐"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.staff==0">否</span>
                        <span v-if="scope.row.staff==1">是</span>
                    </template>
                </el-table-column>


                <el-table-column
                        fixed="right"
                        width="195"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini"
                                   :type="scope.row.payStatus==0?'primary':''"
                                   :disabled="scope.row.payStatus!=0"
                                   @click="handleChangeOpenPassword(scope.row)">线下收款
                        </el-button>
                        <el-button size="mini"
                                   :type="scope.row.orderStatus==2?'primary':''"
                                   :disabled="scope.row.orderStatus!=2"
                                   @click="handleFinishOrder(scope.row)">订单完成
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <br>
            <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNumber"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.totalCount">
            </el-pagination>
        </el-main>
    </el-container>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {dataExportDo, docollect, searchByCondition, dofinish, dofinishs} from '../../api/orderManage'

    const orderStatusOptions = [
        {label: "待付款", value: 0},
        {label: "备餐中", value: 1},
        {label: "配送中", value: 2},
        {label: "完成", value: 3},
        {label: "取消", value: -1},
        // {label: "订单过期", value: -2},
        {label: "申请退款", value: -3},
        {label: "已退款", value: -4}
    ]
    const payStatusOptions = [
        {label: "待支付", value: 0},
        {label: "支付成功", value: 1},
        {label: "支付失败", value: -1},
        {label: "退款中", value: -2},
        {label: "退款完成", value: -3}
    ]
    let userTypes = [
        {label: "网吧用户", value: 'barUser'},
        {label: "员工餐", value: 'staff'}
    ]
    export default {
        name: "orderRecord",
        data() {
            return {
                baseUrl: process.env.VUE_APP_BASEAPI + 'fastdfs/uploadFile',
                tableData: [],
                pageInfo: {
                    totalCount: 0,
                    pageNumber: 1,
                    pageSize: 10,
                    pageOrderName: 'createTime',
                    pageOrderBy: 'descending',
                },
                query: {
                    date: '',//日期
                    tradeno: '',//订单号
                    goodsName: '',//商品名称
                    targetAddress: '',//收货地址
                    targetUser: '',//收货人姓名
                    targetMobile: '',//收货人电话
                    mobile: '',//下单人电话
                    orderStatus: '',//订单状态
                    payStatus: '',//支付状态
                    barUser: '',//是否网吧用户
                    staff: '',//是否员工餐
                    userType: '',//用户类型
                },
                formKey: {},
                orderStatusOptions,
                payStatusOptions,
                userTypes,
                selectArray: []
            }
        },
        computed: {
            ...mapGetters([
                'userInfo'
            ]),
        },
        methods: {
            handleSearch() {
                this.pageInfo.pageNumber = 1
                this.searchByCondition()
            },
            async searchByCondition() {
                try {
                    if (this.query.date && this.query.date.length) {
                        this.query.start = this.query.date[0]
                        this.query.end = this.query.date[1]
                    } else {
                        this.query.start = ''
                        this.query.end = ''
                    }
                    let query = Object.assign({}, this.pageInfo, this.query)
                    let formData = {}
                    for (let i in query) {
                        if (query[i] !== '') {
                            formData[i] = query[i]
                            // if(i==='userType'){
                            //     formData[query[i]]=1
                            // }else{
                            //
                            // }
                        }
                    }
                    let resonse = await searchByCondition(formData)
                    let {data} = resonse;
                    this.tableData = data.list;
                    this.pageInfo.totalCount = data.totalCount
                } catch (e) {
                    console.log(e)
                }
            },
            clearSearch() {
                this.$refs.searchForm.resetFields();
                this.handleSearch()
            },
            handleSizeChange(val) {
                this.pageInfo.pageSize = val
                this.pageInfo.pageNumber = 1
                this.searchByCondition()
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.pageInfo.pageNumber = val
                this.searchByCondition()
                console.log(`当前页: ${val}`);
            },
            //线下收款
            handleChangeOpenPassword(row) {
                console.log(254, row)
                this.$confirm('确认已收款?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    try {
                        await docollect(row.id);
                        this.$message({
                            type: 'success',
                            message: '更新成功!'
                        });
                        this.searchByCondition()
                    } catch (e) {
                    }
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            handleSelectionChange(val) {
                this.selectArray = val
            },
            //完成订单

            handleFinishOrder(row) {
                this.$confirm('确定订单已完成?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    try {
                        await dofinish(row.id);
                        this.$message({
                            type: 'success',
                            message: '更新成功!'
                        });
                        this.searchByCondition()
                    } catch (e) {

                    }
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            async handleFinishOrders() {
                try {
                    await this.$confirm('确定订单已完成?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    var ids = [];
                    this.selectArray.forEach(function (val) {
                        ids.push(val.id);
                    });
                    await dofinishs({idsStr: ids.toString()});
                    this.$message({
                        type: 'success',
                        message: '更新成功!'
                    });
                    this.searchByCondition()
                } catch (e) {

                }

            },
            async handleDownload() {
                try {
                    if (this.query.date && this.query.date.length) {
                        this.query.start = this.query.date[0]
                        this.query.end = this.query.date[1]
                    } else {
                        this.query.start = ''
                        this.query.end = ''
                    }
                    let query = Object.assign({}, this.pageInfo, this.query)
                    let formData = {}
                    for (let i in query) {
                        if (query[i] !== '' && i !== 'total' && query[i] !== -1 && i !== 'date' && i !== 'rows' && i !== 'page') {
                            formData[i] = query[i]
                        }
                    }
                    let {data} = await dataExportDo(formData);
                    let reader = new FileReader();
                    reader.readAsDataURL(data);
                    reader.onload = e => {
                        let now = Date.now();
                        let a = document.createElement('a');
                        a.download = `${now}.csv`;
                        a.href = e.target.result;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    };
                } catch (e) {

                }
            },
            formatOrderStatus(row, column, cellValue, index) {
                let {orderStatus} = row
                let orderOption = this.orderStatusOptions.find(order => order.value === orderStatus)
                return orderOption ? orderOption.label : '--'
            },
            formatPayStatus(row, column, cellValue, index) {
                let {payStatus} = row
                let payOption = this.payStatusOptions.find(pay => pay.value === payStatus)
                return payOption ? payOption.label : '--'
            }
        },
        mounted() {
            this.searchByCondition()
        }
    }
</script>

<style lang="less">
    .btn-group {
        button {
            margin-left: 20px;
        }

    }

    .el-input--prefix .el-input__inner {
        padding-left: 46px;
    }

    .el-input__prefix {
        left: 15px;
        color: #303133;
        i {
            font-style: normal;
        }
    }

    .icon-cursor {
        cursor: pointer;
        margin-right: 10px;
        &:hover {
            color: #348EED;
        }
    }

    .avatar-uploader {
        .el-upload {
            border: 1px solid #DCDFE6;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 72px;
            display: block;
            &:hover {
                border-color: #409EFF;
            }
        }
        .cw-icon-tupian {
            font-size: 24px;
            color: #c0c4cc;
            width: 72px;
            height: 72px;
            line-height: 72px;
            text-align: center;
        }
        .avatar {
            width: 72px;
            height: 72px;
            display: block;
        }
    }


</style>
